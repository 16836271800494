.intro {
  height: calc(100vh - 50px);

  video {
    width: 100%;
    margin-top: -50px;
  }

  div {
    width: 100%;
    height: calc(100vh - 50px);
    position: absolute;
    top: 0%;
  }
}
