@import "colors";

nav{
    display: flex;
    width: 100%;
    padding: 1rem;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    z-index: 10;
    top: 0%;
    background-color: white;

    >h1{
        text-transform: uppercase;
        // border: 2px solid blue;
    }
    >main{
        // border: 2px solid blue;
        width: 90%;
        display: flex;
        justify-content: flex-end;
        text-decoration: none;

        >a{
            margin: 1rem;
            color: black;
            text-decoration: none;
            &:hover{
                color: $pink;
            }
        }

    }
}