@import "colors";

.section {
  padding-top: 15rem;
  > div {
    margin: auto;
    max-width: 48rem;
    height: 120vh;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    justify-content: space-evenly;
  }

  h3 {
    width: 80%;
    font: 300 54px $heading;
  }

  p {
    font-size: 19px;
    font-family: $text;
    line-height: 1.9;
  }

  button {
    font: 600 14px $text;
    padding: 0.9rem 1.25rem;
    border: none;
    border-radius: 7px;
    transform-origin: bottom;
    transition: transform 0.5s;
    letter-spacing: 2px;
    &:hover {
      transform: skewX(-10deg);
    }
  }
}
